"use client";

import React from "react";
import { notFound } from "next/navigation";

import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import {
  BookOpenCheck,
  CloudDownload,
  Factory,
  IndianRupee,
  Languages,
  Sparkles,
  UsersRound,
} from "lucide-react";
import EnquiryButton from "@/src/app/(client)/_features/components/EnquiryButton";
import { baseURL } from "@/src/config";
import HeroEnquiryFormWrapper from "@/src/app/(client)/_features/components/HeroEnquiryFormWrapper";

interface CourseHeroProps {
  title: string;
  subTitle: string;
  price: string;
  duration: string;
  totalNumProjects: string;
  blog: string;
  language: string;
  imagePath: string;
  brochureUrl: string;
}

export default function CourseHero({
  title,
  subTitle,
  price,
  duration,
  totalNumProjects,
  blog,
  language,
  imagePath,
  brochureUrl,
}: CourseHeroProps) {
  return (
    <Box
      id="hero"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        width: "100%",
        backgroundImage: {
          xs: "linear-gradient(135deg, #051937, #004d7a, #008793)",
          md: "linear-gradient(135deg, #051937, #004d7a, #008793)",
          // md: `url("${imagePath}")`,
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: { xs: 6, sm: 8 },
          zIndex: 2,
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={12} md={8}>
            <Chip
              size="small"
              icon={<Sparkles color="#FF7D29" size={14} />}
              label="For all levels of expertise"
              variant="outlined"
              sx={{ color: "#FF7D29", mb: 2 }}
            />
            <Typography
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#fff" : "primary.light",
              }}
              mb={3}
            >
              {title}
            </Typography>
            <Box color={"#fff"}>
              <Typography
                component="div"
                sx={{ fontSize: "18px" }}
                dangerouslySetInnerHTML={{
                  __html: subTitle,
                }}
              />
            </Box>

            <Stack direction="row" spacing={1} sx={{ py: 2, overflow: "auto" }}>
              <Chip
                size="small"
                variant="outlined"
                icon={<Factory color="#FFFF80" size={14} />}
                sx={{ fontSize: "12px", color: "#FFFF80" }}
                label="Placement assistance"
              />
              <Chip
                size="small"
                variant="outlined"
                icon={<BookOpenCheck color="#FFFF80" size={14} />}
                sx={{ fontSize: "12px", color: "#FFFF80" }}
                label="1:1 mentorship"
              />
              <Chip
                size="small"
                sx={{ fontSize: "12px", color: "#FFFF80" }}
                icon={<UsersRound color="#FFFF80" size={14} />}
                label="Industry expert Faculty"
                variant="outlined"
              />
            </Stack>
            <Typography
              display="flex"
              gap={2}
              alignItems="center"
              color="#fff"
              variant="subtitle1"
              sx={{
                alignSelf: "center",
                width: { sm: "100%", md: "80%", mb: 3 },
              }}
            >
              Price:
              <Box display="flex" alignItems="center">
                <IndianRupee size={18} />
                <strong>{price}</strong>
              </Box>
            </Typography>
            <Typography
              display="flex"
              gap={2}
              color="#fff"
              variant="subtitle1"
              sx={{
                alignSelf: "center",
                width: { sm: "100%", md: "80%", mb: 3 },
              }}
            >
              Duration: <strong>{duration}</strong>
            </Typography>
            <Typography
              display="flex"
              gap={2}
              color="#fff"
              variant="subtitle1"
              sx={{
                alignSelf: "center",
                width: { sm: "100%", md: "80%", mb: 3 },
              }}
            >
              Language:
              <Chip
                size="small"
                sx={{ color: "#fff" }}
                icon={<Languages color="#fff" size={18} />}
                label={language}
              />
            </Typography>
            <Box display={"flex"} alignItems={"center"} gap={2} mt={4}>
              {brochureUrl && (
                <EnquiryButton
                  brochureUrl={brochureUrl}
                  action="DownloadBrochure"
                  Icon={CloudDownload}
                  text="Download Brochure"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <HeroEnquiryFormWrapper />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
