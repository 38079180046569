"use client";

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid, alpha } from "@mui/material";
import CourseCard from "../../app/(client)/(home)/_components/CourseCard";

export default function SuggestedCourses({ courseIds, requestFrom = "" }) {
  const [courses, setCourses] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    setLoading(true);
    const urls = courseIds.map((cid) => `/api/courses/${cid}`);

    const coursesPromises = urls.map((url) =>
      fetch(`${url}`).then((res) => res.json())
    );
    Promise.all(coursesPromises).then((data) => {
      setLoading(false);
      setCourses(data);
    });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!courses) {
    return;
  }

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(to right, #CEE5FD, #FFF)" // Gradient to right for light mode
            : `linear-gradient(to right, #02294F, ${alpha("#090E10", 0.0)})`, // Gradient to right for dark mode
        backgroundSize: "200% 100%", // Set background size to 200% for smooth transition
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          pt: { xs: 4, sm: 6 },
          pb: { xs: 4, sm: 12 },
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography textAlign={"center"} variant="h4" color="text.primary">
          Explore more courses
        </Typography>
        <Typography
          mb={8}
          mt={1}
          variant="body1"
          color="text.secondary"
          textAlign={"center"}
        >
          Expand Your Interests of learning
        </Typography>

        <Grid container spacing={2}>
          {courses.map((course) => (
            <Grid key={course._id} item xs={6} sm={6} md={4} lg={3}>
              <CourseCard
                requestFrom={requestFrom}
                key={course._id}
                course={course}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
