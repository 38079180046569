"use client";

import React from "react";
import { Factory, GraduationCap, University } from "lucide-react";
import { Box, Container, Grid, Typography, alpha } from "@mui/material";

export default function GoalAchieved() {
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(to right, #CEE5FD, #FFF)" // Gradient to right for light mode
            : `linear-gradient(to right, #02294F, ${alpha("#090E10", 0.0)})`, // Gradient to right for dark mode
        backgroundSize: "200% 100%", // Set background size to 200% for smooth transition
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <Grid container>
          <Grid item xs={6} sm={3}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              borderRight={{ xs: 0, sm: 1 }}
            >
              <GraduationCap size={48} />
              <Typography variant="h4">20k+</Typography>
              <Typography variant="subtitle2">TrainedStudent</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              borderRight={{ xs: 0, sm: 1 }}
            >
              <Factory size={48} />
              <Typography variant="h4">17k+</Typography>
              <Typography variant="subtitle2">PlacedStudent</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              borderRight={{ xs: 0, sm: 1 }}
            >
              <University size={48} />
              <Typography variant="h4">750+</Typography>
              <Typography variant="subtitle2">CollegeTrainings</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Factory size={48} />
              <Typography variant="h4">234+</Typography>
              <Typography variant="subtitle2">CorporateTrainings</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
