"use client";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { CheckCircle2 } from "lucide-react";
import Image from "next/image";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export default function PrerequisitesList({ prerequisites }) {
  return (
    <Box>
      <Container
        id="objective-list"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography variant="h2" color="text.primary" sx={{ mb: 2 }}>
            Check Your Eligibility
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Our faculty are all professionally qualified and have rich industry
            experience, essential for giving a practical context to concepts
          </Typography>
        </Box>
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Image
              loading="lazy"
              alt="check your eligibility"
              width={400}
              height={300}
              sizes="100vw"
              src={"/images/checklist.webp"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <List dense={false}>
              {prerequisites.map((prerequisite) => {
                return (
                  <ListItem key={prerequisite} disableGutters>
                    <CheckCircle2 size={"18px"} />
                    <Typography ml={2} variant="body1">
                      {prerequisite}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
