"use client";

import React from "react";
import { Box } from "@mui/material";

export default function ContactPageMap() {
  return (
    <Box width="100%">
      <iframe
        title="geo-location-brainmentors"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.7356500959772!2d77.441880773942!3d28.697553281127842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d015ef7e69fc5%3A0x7c84444292e833c2!2sBrain%20Mentors%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1683176095028!5m2!1sen!2sin"
        style={{ border: 0, width: "100%", height: "60vh" }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy={"no-referrer-when-downgrade"}
      ></iframe>
    </Box>
  );
}
