"use client";

import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";

import EnquiryButton from "@/src/app/(client)/_features/components/EnquiryButton";
import { CloudDownload } from "lucide-react";
import { CourseSyllabusType } from "../../models/courseModel";

export default function SyllabusList({
  syllabus,
  brochureUrl,
}: {
  syllabus: CourseSyllabusType[];
  brochureUrl: string;
}) {
  return (
    <Box>
      <Container
        id="syllabus-list"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography mb={3} textAlign="center" variant="h2">
          Course Roadmap 🛤️
        </Typography>
        <Box
          position="relative"
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          {syllabus.map((sylbs, index) => {
            return (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {sylbs.sectionName}
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="start"
                  >
                    {sylbs.content.map((c) => (
                      <Typography variant="body2" key={c}>
                        {c}
                      </Typography>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              ..........
            </AccordionSummary>
            <AccordionDetails>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="start"
              ></Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {brochureUrl !== "" && (
          <EnquiryButton
            brochureUrl={brochureUrl}
            action="DownloadBrochure"
            Icon={CloudDownload}
            text="Download Full Syllabus"
          />
        )}
      </Container>
    </Box>
  );
}
