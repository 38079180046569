import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EnquiryForm from "./EnquiryForm";

export default function HeroEnquiryFormWrapper() {
  return (
    <Box sx={{ bgcolor: "#fff", borderRadius: "12px", p: 2 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: "24px" }}>
        Book a
        <span
          style={{
            background: "linear-gradient(90deg, #FF6B6B, #FFD93D)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginLeft: "8px",
          }}
        >
          free demo
        </span>
        <span style={{ fontSize: "16px", color: "#666", marginLeft: "8px" }}>
          to know more
        </span>
      </Typography>
      <EnquiryForm />
    </Box>
  );
}
