import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Avatar/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Card/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/CardContent/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/CircularProgress/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Grid/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/PlacementList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/SecondaryBanner.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/blogPageDetail/BlogPageHero.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/contactPageDetail/ContactHero.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/contactPageDetail/ContactPageMap.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/BatchesList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/CourseDescription.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/CourseHero.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/CourseOffering.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/FaqsList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/InstructorsList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/ObjectivesList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/SyllabusList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/coursePageDetail/TargetAudience.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/galleryPageDetail/GalleryHero.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/galleryPageDetail/GalleryImages.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/placementPageDetail/PlacementList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/placementPageDetail/PlacementPageHero.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/staticPageDetail/StaticContent.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/[slug]/_features/components/staticPageDetail/StaticHero.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/PrerequisitesList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/SuggestedCourses.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/TestimonialsList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/TrainingShowCaseVideos.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/VideoTestimonials.tsx")