import React from "react";

export default function Blog({ body }) {
  return (
    <div className="ql-snow">
      <div
        className="ql-editor"
        style={{
          paddingLeft: 0,
          marginLeft: 0,
          fontSize: "20px",
        }}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </div>
  );
}
