"use client";

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { BookUser, Headset } from "lucide-react";

import { toMonthDayYearFormat } from "@/src/shared/utils/dateUtil";
import Blog from "../../../../../_features/components/Blog";
import EnquiryButton from "@/src/app/(client)/_features/components/EnquiryButton";
import { BatchType } from "../../models/courseModel";

export default function BatchesList({ batches }: { batches: BatchType[] }) {
  const today = new Date();

  const upcomingBatches = batches?.filter((batch) => {
    const startDate = new Date(batch.start_date);
    return startDate > today;
  });

  const ongoingBatches = batches?.filter((batch) => {
    const startDate = new Date(batch.start_date);
    return startDate <= today;
  });

  return (
    <Box bgcolor="#000" color={"#fff"}>
      <Container
        id="batches"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography textAlign={"center"} variant="h2" mb={2} color="primary">
            Batches
          </Typography>
          <Typography variant="body1">
            Enroll today to start learning on your terms!
          </Typography>
        </Box>
        {ongoingBatches.length > 0 && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Grid justifyContent="center" container spacing={2}>
              {ongoingBatches.map((batch, index) => (
                <Grid item xs={12} sm={12} md={6} key={index}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flexGrow: 1,
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={4}
                        sx={{
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          sx={{ flex: "1 1 auto", minWidth: 0 }}
                        >
                          {batch.batch_name}
                        </Typography>
                        <Typography
                          fontWeight="bold"
                          color="secondary"
                          variant="body2"
                          sx={{ flex: "0 0 auto" }}
                        >
                          {toMonthDayYearFormat(batch.start_date)}
                        </Typography>
                      </Box>
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: batch.description,
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {upcomingBatches.length > 0 && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h5" mb={2} color="#fff">
              Upcoming batches list
            </Typography>
            <Grid justifyContent="center" container spacing={2}>
              {upcomingBatches.map((batch, index) => (
                <Grid item xs={12} sm={12} md={6} key={index}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flexGrow: 1,
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={4}
                        sx={{
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="h6"
                          color="text.secondary"
                          sx={{ flex: "1 1 auto", minWidth: 0 }}
                        >
                          {batch.batch_name}
                        </Typography>
                        <Typography
                          fontWeight="bold"
                          color="secondary"
                          variant="body2"
                          sx={{ flex: "0 0 auto" }}
                        >
                          {toMonthDayYearFormat(batch.start_date)}
                        </Typography>
                      </Box>
                      <Blog body={batch.description} />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box mr={1}>
            <EnquiryButton
              action="ThankYouMessage"
              Icon={BookUser}
              text="Speak to counsellor"
            />
          </Box>
          <EnquiryButton
            action="ThankYouMessage"
            Icon={Headset}
            text="Talk to trainer"
          />
        </Box>
      </Container>
    </Box>
  );
}
