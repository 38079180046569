"use client";

import React from "react";
import Image from "next/image";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  ImageList,
  ImageListItem,
} from "@mui/material";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { awsConfig } from "@/src/config";

export default function GalleryImages({ images }) {
  const [openPopup, setOpenPopup] = React.useState(false);
  const handleClose = () => {
    setOpenPopup(false);
  };
  const allImages = images.map((image) => {
    return {
      original: `https://${awsConfig.awsImageAssetBucket}.s3.ap-south-1.amazonaws.com/${image}?w=164&h=164&fit=crop&auto=format`,
    };
  });
  return (
    <Box width="100%">
      <Container
        id="instructors-list"
        sx={{
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ImageList sx={{ width: "100%" }} cols={4} rowHeight={164}>
          {images.map((item) => (
            <ImageListItem key={item}>
              <Image
                fill
                objectFit="cover"
                onClick={() => setOpenPopup(true)}
                src={`https://${awsConfig.awsImageAssetBucket}.s3.ap-south-1.amazonaws.com/${item}?w=164&h=164&fit=crop&auto=format`}
                alt={item}
                style={{ cursor: "pointer" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>

      <Dialog
        sx={{
          bgcolor: "none",
          border: "none",
        }}
        onClose={handleClose}
        open={openPopup}
        maxWidth={"lg"}
      >
        <DialogContent sx={{ p: 0, m: 0 }} dividers>
          <ImageGallery
            autoPlay={true}
            slideDuration={1000}
            items={allImages}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
