"use client";

import React from "react";
import useSWR from "swr";
import Close from "@mui/icons-material/Close";
import YouTube from "@mui/icons-material/YouTube";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import SharedCarousel from "@/src/shared/components/Carousel";

import { appConfig } from "@/src/config";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function VideoTestimonials() {
  const [open, setOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState("");
  // SWR fetch with cache management and fallback data
  const { data, isLoading } = useSWR(
    `https://www.googleapis.com/youtube/v3/playlistItems?key=${appConfig.youTubeAPI}&playlistId=PLoTH3bGuyKHfxcYsb6HbZNPqsmD5HHZiv&part=contentDetails,snippet&maxResults=50`,
    fetcher,
    {
      revalidateOnFocus: false, // Disable revalidation on window focus to avoid unnecessary calls
      dedupingInterval: 60000, // Cache for 1 minute
    }
  );

  if (isLoading) {
    return (
      <>
        <Box textAlign={"center"}>
          <CircularProgress />
        </Box>
      </>
    );
  }
  if (data) {
    return (
      <>
        <Box
          sx={(theme) => ({
            width: "100%",
            backgroundImage:
              theme.palette.mode === "light"
                ? "linear-gradient(to right, #CEE5FD, #FFF)" // Gradient to right for light mode
                : `linear-gradient(to right, #02294F, ${alpha(
                    "#090E10",
                    0.0
                  )})`, // Gradient to right for dark mode
            backgroundSize: "200% 100%", // Set background size to 200% for smooth transition
            backgroundRepeat: "no-repeat",
          })}
        >
          <Container
            sx={{
              pt: { xs: 4, sm: 6 },
              pb: { xs: 4, sm: 12 },
              gap: { xs: 3, sm: 6 },
            }}
          >
            <Typography variant="h2" textAlign={"center"}>
              🎤 Voices of Success
            </Typography>
            <Typography
              mb={5}
              mt={1}
              variant="body1"
              color="text.secondary"
              textAlign={"center"}
            >
              Real students sharing their stories of growth, achievement, and
              transformation with Brain Mentors.
            </Typography>

            <SharedCarousel>
              {data.items.map((item: any) => (
                <Card
                  key={item.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    height: "100%",
                    marginX: 1,
                  }}
                >
                  <CardMedia
                    loading="lazy"
                    component="img"
                    height="194"
                      image={item.snippet?.thumbnails?.medium?.url ?? ""}
                    alt=""
                  />

                  <Box
                    position={"absolute"}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                    left={0}
                    right={0}
                    textAlign={"center"}
                    top={"30%"}
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedVideo(`${item.contentDetails.videoId}`);
                        setOpen(true);
                      }}
                    >
                      <YouTube sx={{ fontSize: "3rem" }} color="error" />
                    </IconButton>
                  </Box>
                </Card>
              ))}
            </SharedCarousel>
          </Container>
        </Box>
        {open && (
          <Dialog
            fullWidth
            open={open}
            onClose={setOpen}
            aria-describedby="alert-dialog-slide-description"
          >
            <Box>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ position: "absolute", right: "0", zIndex: 1 }}
              >
                <Close sx={{ fontSize: "2rem", color: "#fff" }} />
              </IconButton>
            </Box>
            <iframe
              allowFullScreen
              loading="lazy"
              width="100%"
              height="340px"
              src={`https://www.youtube.com/embed/${selectedVideo}?autoplay=0&mute=1`}
              title="YouTube video player"
            />
          </Dialog>
        )}
      </>
    );
  }
}
