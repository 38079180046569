"use client";
import React from "react";
import Box from "@mui/material/Box";
import { CheckCircle2 } from "lucide-react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function TargetAudience({ targetAudience }) {
  return (
    <Box bgcolor={"#000"} color={"#fff"}>
      <Container
        id="target-audience"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography mb={2} variant="h2" textAlign="center" color="primary">
            Target Audience
          </Typography>
          <Typography variant="subtitle1">
            This focuses on the benefits the audience can achieve by taking the
            course. (e.g., Unlock your potential using this course).
          </Typography>
        </Box>
        <List dense={false}>
          {targetAudience.map((audience) => {
            return (
              <ListItem key={audience} disableGutters>
                <CheckCircle2 size={"18px"} />
                <ListItemText sx={{ ml: 2 }} primary={audience} />
              </ListItem>
            );
          })}
        </List>
      </Container>
    </Box>
  );
}
