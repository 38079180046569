"use client";

import React from "react";
import Image from "next/image";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import YouTube from "@mui/icons-material/YouTube";

export default function CourseDescription({ description, promoVideo }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <Container
        id="course-desc"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={promoVideo ? 6 : 12}
            md={promoVideo ? 6 : 12}
            lg={promoVideo ? 6 : 12}
          >
            <Box>
              <Typography
                component="div"
                sx={{ fontSize: "18px" }}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Box>
          </Grid>

          {promoVideo && (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box
                sx={{
                  width: "100%", // Make the width responsive
                  maxWidth: "640px", // Set a maximum width for larger screens
                  aspectRatio: "16/9", // Maintain a 16:9 aspect ratio
                  position: "relative",
                  border: 1,
                  borderColor: "divider",
                }}
              >
                <Image
                  loading="lazy"
                  alt="Know about courses Brain Mentors"
                  fill
                  src="/images/promo-video-thumb.webp"
                />
                <Box position={"absolute"} top="34%" left="43%">
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <YouTube sx={{ fontSize: "5rem" }} color="error" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
      {open && (
        <Dialog
          fullWidth
          open={open}
          onClose={setOpen}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              sx={{ position: "absolute", right: "0", zIndex: 1 }}
            >
              <Close sx={{ fontSize: "2rem", color: "#fff" }} />
            </IconButton>
          </Box>
          <iframe
            style={{ border: "none" }}
            loading="lazy"
            width="100%"
            allowFullScreen
            height="300"
            src={`${promoVideo}`}
            title={`${promoVideo}`}
          />
        </Dialog>
      )}
    </Box>
  );
}
