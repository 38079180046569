"use client";

import React from "react";
import { Box, Container } from "@mui/material";
import Blog from "../../../../../_features/components/Blog";

export default function StaticContent({ pageDetail }) {
  return (
    <Box width="100%">
      <Container
        id="instructors-list"
        sx={{
          pb: { xs: 2, sm: 4 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Blog body={pageDetail?.body} />
      </Container>
    </Box>
  );
}
