import moment from "moment-timezone";

function toDatePickerFormat(date: Date | string): string {
  const utcMoment = moment.utc(date);
  const istMoment = utcMoment.tz("Asia/Kolkata");
  return istMoment.format("YYYY-MM-DD");
}

function toDatePickerFormatFor(date: Date | string): string {
  const utcMoment = moment.utc(date);
  const istMoment = utcMoment.tz("Asia/Kolkata");
  return istMoment.toISOString();
}

function fromNow(date) {
  if (date) {
    return moment(date).fromNow();
  }
  return "Never";
}

function toMonthDayYearFormat(date: Date | string): string {
  return moment(date).utc().format("MMMM Do, YYYY");
}

export {
  fromNow,
  toDatePickerFormat,
  toDatePickerFormatFor,
  toMonthDayYearFormat,
};
