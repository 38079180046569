"use client";
import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { CheckCheck } from "lucide-react";

export default function ObjectivesList({ objectives }) {
  return (
    <Box bgcolor={"#000"} color={"#fff"}>
      <Container
        id="objective-list"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography variant="h2" color="primary">
            What you&apos;ll learn
          </Typography>
        </Box>

        <Grid spacing={2} container alignItems="center">
          {objectives.map((objective) => {
            return (
              <Grid key={objective} item xs={6}>
                <Box gap={2} alignItems="center" display="flex">
                  <CheckCheck size={20} />
                  <Typography variant="body1">{objective}</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
