"use client";

import React from "react";

import Close from "@mui/icons-material/Close";
import YouTube from "@mui/icons-material/YouTube";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { alpha } from "@mui/material/styles";

import SharedCarousel from "@/src/shared/components/Carousel";

import { appConfig } from "@/src/config";

export default function TrainingShowCaseVideos({ videos }) {
  const [open, setOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState("");

  return (
    <>
      <Box
        sx={(theme) => ({
          width: "100%",
          backgroundImage:
            theme.palette.mode === "light"
              ? "linear-gradient(to right, #CEE5FD, #FFF)"
              : `linear-gradient(to right, #02294F, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "200% 100%",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          sx={{
            pt: { xs: 4, sm: 6 },
            pb: { xs: 4, sm: 12 },
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Typography variant="h2" mb={4} textAlign={"center"}>
            Sample Preview Sessions 🎬
          </Typography>

          <SharedCarousel>
            {videos.map((video, index) => (
              <VideoWithThumbnail
                key={index}
                embedUrl={video}
                setOpen={setOpen}
                setSelectedVideo={setSelectedVideo}
              />
            ))}
          </SharedCarousel>
        </Container>
      </Box>
      {open && (
        <Dialog
          fullWidth
          open={open}
          onClose={setOpen}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              sx={{ position: "absolute", right: "0" }}
            >
              <Close sx={{ fontSize: "2rem", color: "#fff" }} />
            </IconButton>
          </Box>
          <iframe
            allowFullScreen
            loading="lazy"
            width="100%"
            height="340px"
            src={`${selectedVideo}`}
            title="YouTube video player"
          />
        </Dialog>
      )}
    </>
  );
}

function VideoWithThumbnail({ embedUrl, setSelectedVideo, setOpen }) {
  const [thumbnailUrl, setThumbnailUrl] = React.useState(null);

  React.useEffect(() => {
    const videoId = embedUrl.split("/embed/")[1].split("?")[0]; // Extract video ID
    const fetchThumbnail = async () => {
      const videoData = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${appConfig.youTubeAPI}`
      );
      const videoJson = await videoData.json();

      setThumbnailUrl(videoJson.items[0].snippet.thumbnails.high.url);
    };
    fetchThumbnail();
  }, [embedUrl]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
        height: "100%",
        marginX: 1,
      }}
    >
      {thumbnailUrl && (
        <CardMedia
          component="img"
          height="194"
          image={thumbnailUrl}
          alt="Brain Mentors IT Training Institute "
          srcSet={`${thumbnailUrl}?w=320 320w, ${thumbnailUrl}?w=480 480w`}
          sizes="(max-width: 600px) 320px, 480px"
          loading="lazy"
        />
      )}
      <Box
        position={"absolute"}
        marginLeft={"auto"}
        marginRight={"auto"}
        left={0}
        right={0}
        textAlign={"center"}
        top={"30%"}
      >
        <IconButton
          onClick={() => {
            setSelectedVideo(embedUrl);
            setOpen(true);
          }}
        >
          <YouTube sx={{ fontSize: "3rem" }} color="error" />
        </IconButton>
      </Box>
    </Card>
  );
}
