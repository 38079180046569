"use client";

import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

export default function InstructorsList({ instructors }) {
  return (
    <Box bgcolor={"#000"} color={"#fff"}>
      <Container
        id="instructors-list"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
            Learn Directly from Industry Leaders! 🌟
          </Typography>
          <Typography variant="subtitle1">
            Our instructors are real-world experts 🎓 who’ve mastered their
            fields 🏢. They don’t just teach—they share insider knowledge 🌍 and
            hands-on skills that set you apart. Gain career-ready expertise 📚
            and step confidently toward your future success! 🚀.
          </Typography>
        </Box>
        <Grid justifyContent="center" container spacing={2}>
          {instructors.map((instructor, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: "flex",
                  border: "1px solid #fff",
                  height: 170,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        fontWeight="bold"
                        component="div"
                        variant="subtitle2"
                      >
                        {instructor.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        sx={{ color: "text.secondary" }}
                      >
                        {instructor.headline}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CardMedia
                        component="img"
                        sx={{ width: 40, height: 40, mt: 1 }}
                        image={`${instructor.worked_with}`}
                        alt={instructor.worked_with}
                      />
                    </Box>
                  </CardContent>
                </Box>
                <CardMedia
                  loading="lazy"
                  component="img"
                  sx={{ width: 125, objectFit: "fill" }}
                  image={`${instructor.photo}`}
                  alt={instructor.name}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
