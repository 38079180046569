"use client";
import React from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import Blog from "../../../../../_features/components/Blog";
import HeroEnquiryFormWrapper from "@/src/app/(client)/_features/components/HeroEnquiryFormWrapper";

interface StaticHeroProps {
  title: string;
  subTitle: string;
  imagePath: string;
}

export default function StaticHero({
  title,
  subTitle,
  imagePath,
}: StaticHeroProps) {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage: "url('/images/CFA-Banner.webp')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: { xs: 6, sm: 8 },
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              component="h1"
              variant="h1"
              sx={{
                fontSize: "clamp(3rem, 10vw, 4rem)",
                color: (theme) =>
                  theme.palette.mode === "light" ? "#fff" : "primary.light",
              }}
              mb={3}
            >
              {title}
            </Typography>
            <Typography
              color="#fff"
              variant="h6"
              sx={{
                alignSelf: "center",
                width: { sm: "100%", md: "80%", mb: 3 },
              }}
            >
              <Blog body={subTitle} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <HeroEnquiryFormWrapper />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
