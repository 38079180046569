"use client";

import { Avatar, Box, Card, CardHeader, Grid } from "@mui/material";
import React from "react";

export default function PlacementList({ placements }) {
  return (
    <Grid container spacing={2} mb={5}>
      {placements.map((placement) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={placement._id}>
          <Card
            key={placement._id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              flexGrow: 1,
              p: 1,
              height: "100%",
              marginX: 1,
            }}
          >
            <Avatar
              alt={`${placement.name}`}
              sx={{ width: 100, height: 100, objectFit: "contain" }}
              src={placement.photo_url}
              title={placement.name}
            />
            <Box display="flex" alignItems="center">
              <CardHeader
                title={placement.name}
                subheader={placement.designation}
              />
              <Avatar
                alt={`${placement.company_logo}`}
                sx={{ width: 50, height: 50, objectFit: "cover" }}
                src={placement.company_logo}
                title={placement.company_logo}
              />
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
